<template>
  <div class="annex">
    <el-row style="margin-bottom: 15px" v-if="source == 1">
      <el-col :span="18">
        <el-descriptions :column="4" border>
          <el-descriptions-item label="项目类型:">
            <div style="padding: 10px">
              {{ projectInformation.projectType | dict(DisposableType) }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="项目来源:">
            <div style="padding: 10px">
              {{ projectInformation.projectSource | dict(projectSource) }}
            </div>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="项目来源详细:">
            <div style="padding: 10px;">
              {{
                projectInformation.projectSourceDetailed
                  | dict(sourceDetailedType)
              }}
            </div>
          </el-descriptions-item> -->
        </el-descriptions>
      </el-col>
      <el-col :span="6">
        <div style="padding-left: 62%; padding-top: 8px">
          <el-button type="success" plain @click="changeFn">变更记录信息</el-button>
        </div>
      </el-col>
    </el-row>
    <div style="padding-left: 91%; padding-bottom: 8px" v-else>
      <el-button type="success" plain @click="changeFn">变更记录信息</el-button>
    </div>
    <el-collapse v-model="activeNames">
      <template v-if="source == 1">
        <template v-for="(item, index) in ExpenseList">
          <el-collapse-item
            v-if="item.type == projectInformation.projectType"
            :title="item.type | dict(DisposableType, 'dictVal', 'dictName')"
            :name="index"
            :key="index">
            <el-descriptions :column="1" border>
              <template v-for="a in item.ruleList">
                <el-descriptions-item :key="a.id" label-class-name="labelClassName">
                  <span class="expense">
                    <span class="expense_name">{{ a.name }}</span>
                    <span style="margin-right: 3%">=</span>
                    <template v-for="b in a.ruleDataList">
                      <span :key="b.id">
                        <template v-if="b.ruleType == 'PER_CENT'">
                          <span :class="b.tipsShow == 1 ? 'tips' : ''"></span>
                        </template>
                        <span v-if="b.ruleType == 'FIELD'">{{ b.name }}</span>
                        <span v-if="b.ruleType == 'OPERATOR'" class="operator">{{ b.code }}</span>
                        <el-input
                          :disabled="disabled"
                          v-if="b.ruleType == 'PER_CENT'"
                          :class="
                            b.isAlreadyChange == 1
                              ? 'rulesInput color bd_input'
                              : 'rulesInput bd_input'
                          "
                          @input="inputFn(b.code, b.id)"
                          @blur="blurFn(b.id)"
                          @focus="focusFn(b.id)"
                          style="width: 100px"
                          v-model.number="b.code"></el-input>
                        <template v-if="b.ruleGroupList.length > 0">
                          (
                          <template v-for="c in b.ruleGroupList">
                            <span :key="c.id">
                              <span v-if="c.ruleType == 'FIELD'">{{ c.name }}</span>
                              <el-input
                                @blur="blurSonFn(c.id)"
                                @focus="focusSonFn(c.id)"
                                v-if="c.ruleType == 'VALUE' || c.ruleType == 'PER_CENT'"
                                style="width: 100px"
                                :disabled="disabled"
                                :class="[
                                  c.isAlreadyChange == 1 ? 'color bd_input' : 'bd_input',
                                  c.ruleType == 'PER_CENT' ? 'rulesInput' : '',
                                ]"
                                v-model.number="c.code"></el-input>
                              <span v-if="c.ruleType == 'OPERATOR'" class="operator">{{
                                c.code
                              }}</span>
                              <template v-if="c.ruleGroupChildList.length > 0">
                                <template v-for="d in c.ruleGroupChildList">
                                  <span :key="d.id">
                                    <span v-if="d.ruleType == 'FIELD'">{{ d.name }}</span>
                                    <span v-if="d.ruleType == 'OPERATOR'" class="operator">{{
                                      d.code
                                    }}</span>
                                  </span>
                                </template>
                              </template>
                            </span> </template
                          >)
                        </template>
                      </span>
                    </template>
                  </span>
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-collapse-item>
        </template></template
      >

      <template v-else>
        <template v-for="(item, index) in ExpenseRulesList">
          <el-collapse-item
            :title="item.type | dict(DisposableType, 'dictVal', 'dictName')"
            :name="index"
            :key="index">
            <el-descriptions :column="1" border>
              <template v-for="a in item.ruleList">
                <el-descriptions-item :key="a.id" label-class-name="labelClassName">
                  <span class="expense">
                    <span class="expense_name">{{ a.name }}</span>
                    <span style="margin-right: 3%">=</span>
                    <template v-for="b in a.ruleDataList">
                      <span :key="b.id">
                        <template v-if="b.ruleType == 'PER_CENT'">
                          <span :class="b.tipsShow == 1 ? 'tips' : ''"></span>
                        </template>
                        <span v-if="b.ruleType == 'FIELD'">{{ b.name }}</span>
                        <span v-if="b.ruleType == 'OPERATOR'" class="operator">{{ b.code }}</span>
                        <el-input
                          :disabled="disabled"
                          v-if="b.ruleType == 'PER_CENT'"
                          :class="
                            b.isAlreadyChange == 1
                              ? 'rulesInput color bd_input'
                              : 'rulesInput bd_input'
                          "
                          @input="inputFn(b.code, b.id)"
                          @blur="blurFn(b.id)"
                          @focus="focusFn(b.id)"
                          style="width: 100px"
                          v-model.number="b.code"></el-input>
                        <template v-if="b.ruleGroupList.length > 0">
                          (
                          <template v-for="c in b.ruleGroupList">
                            <span :key="c.id">
                              <span v-if="c.ruleType == 'FIELD'">{{ c.name }}</span>
                              <el-input
                                @blur="blurSonFn(c.id)"
                                @focus="focusSonFn(c.id)"
                                v-if="c.ruleType == 'VALUE' || c.ruleType == 'PER_CENT'"
                                style="width: 100px"
                                :disabled="disabled"
                                :class="[
                                  c.isAlreadyChange == 1 ? 'color bd_input' : 'bd_input',
                                  c.ruleType == 'PER_CENT' ? 'rulesInput' : '',
                                ]"
                                v-model.number="c.code"></el-input>
                              <span v-if="c.ruleType == 'OPERATOR'" class="operator">{{
                                c.code
                              }}</span>
                              <template v-if="c.ruleGroupChildList.length > 0">
                                <template v-for="d in c.ruleGroupChildList">
                                  <span :key="d.id">
                                    <span v-if="d.ruleType == 'FIELD'">{{ d.name }}</span>
                                    <span v-if="d.ruleType == 'OPERATOR'" class="operator">{{
                                      d.code
                                    }}</span>
                                  </span>
                                </template>
                              </template>
                            </span> </template
                          >)
                        </template>
                      </span>
                    </template>
                  </span>
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-collapse-item>
        </template>
      </template>
    </el-collapse>

    <el-dialog append-to-body title="变更记录列表" :visible.sync="changeDialog">
      <el-table :data="changeData" border>
        <el-table-column type="index" align="center" label="序号"></el-table-column>
        <el-table-column
          property="createName"
          align="center"
          label="姓名"
          width="200"></el-table-column>
        <el-table-column property="createdDate" align="center" label="日期" width="200">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column property="module" align="center" label="模块">
          <template slot-scope="scope">
            {{ scope.row.module | dict(rulesType) }}
          </template>
        </el-table-column>
        <el-table-column property="type" align="center" label="变更类型">
          <template slot-scope="scope">
            {{ scope.row.type | dict(DisposableType) }}
          </template>
        </el-table-column>
        <el-table-column property="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="editFn(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :title="source == 0 ? '全局规则-可支配费用-更变记录' : '项目规则-可支配费用-更变记录'"
      :visible.sync="dialogVisible"
      append-to-body
      width="90%"
      v-loading="loading">
      <ChangeDetails
        :rulesNum="1"
        :isType="isType"
        :source="source"
        :rulesType="DisposableType"
        :list="list"></ChangeDetails>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    ChangeDetails: () => import('./ChangeDetails.vue'),
  },
  props: {
    source: {
      type: Number,
      default: 0,
    },
    ExpenseRulesModule: {
      type: Object,
      default: function () {
        return {}
      },
    },
    projectSource: {
      type: Array,
      default: function () {
        return []
      },
    },
    sourceDetailedType: {
      type: Array,
      default: function () {
        return []
      },
    },
    rulesType: {
      type: Array,
      default: function () {
        return []
      },
    },
    ExpenseRulesList: {
      type: Array,
      default: function () {
        return []
      },
    },
    usedExpenseRulesList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      DisposableType: [],
      changeDialog: false,
      dialogVisible: false,
      disabled: false,
      loading: true,
      changeData: [],
      list: [],
      code: '',
      sonCode: '',
      activeNames: this.source == 1 ? [0] : [],
      ExpenseList: [],
      isType: '',
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
  },
  filters: {
    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  watch: {
    source: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (this.permission(['PROJECT_PERFORMANCE_EDIT'])) {
          this.disabled = false
        } else if (this.permission(['PERFORMANCE_RULES_EDIT'])) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      },
    },
    ExpenseRulesList: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (this.source == 1) {
          let newobj = {} //去重
          this.ExpenseList = newVal.reduce((preVal, curVal) => {
            newobj[curVal.type] ? '' : (newobj[curVal.type] = preVal.push(curVal))
            return preVal
          }, [])
        }
      },
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('PROJECT_TYPE', true)
      .then(res => {
        this.DisposableType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    inputFn(num, id) {
      for (let index = 0; index < this.ExpenseRulesList.length; index++) {
        const item = this.ExpenseRulesList[index]
        for (let i = 0; i < item.ruleList.length; i++) {
          const ite = item.ruleList[i]
          for (let j = 0; j < ite.ruleDataList.length; j++) {
            const element = ite.ruleDataList[j]
            if (element.id == id && element.ruleType == 'PER_CENT') {
              if (num > 100 || num < 0) {
                element.tipsShow = 1
              } else {
                element.tipsShow = 0
              }
            }
          }
        }
      }
    },
    editFn(row) {
      this.loading = true
      if (this.source == 0) {
        this.$api.performanceRules
          .selectLogDetailById(row.id)
          .then(res => {
            this.list = res.data
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else if (this.source == 1) {
        this.$api.project
          .projectRuleSelectLogDetailById(row.id)
          .then(res => {
            this.list = res.data
            this.loading = false
            if (row.type) {
              this.isType = row.type
              this.loading = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.dialogVisible = true
    },
    focusFn(id) {
      try {
        for (let index = 0; index < this.usedExpenseRulesList.length; index++) {
          const item = this.usedExpenseRulesList[index]
          for (let i = 0; i < item.ruleList.length; i++) {
            const ite = item.ruleList[i]
            for (let j = 0; j < ite.ruleDataList.length; j++) {
              const element = ite.ruleDataList[j]
              if (element.id == id && element.ruleType == 'PER_CENT') {
                this.code = element.code
                throw new Error('EndIterative')
              }
            }
          }
        }
      } catch (e) {}
    },
    blurFn(id) {
      try {
        for (let index = 0; index < this.ExpenseRulesList.length; index++) {
          const item = this.ExpenseRulesList[index]
          for (let i = 0; i < item.ruleList.length; i++) {
            const ite = item.ruleList[i]
            for (let j = 0; j < ite.ruleDataList.length; j++) {
              const element = ite.ruleDataList[j]
              if (element.id == id && element.ruleType == 'PER_CENT') {
                if (element.code != this.code) {
                  element.isAlreadyChange = 1
                } else {
                  element.isAlreadyChange = 0
                }
                throw new Error('EndIterative')
              }
            }
          }
        }
      } catch (e) {}
    },
    blurSonFn(id) {
      try {
        for (let index = 0; index < this.ExpenseRulesList.length; index++) {
          const item = this.ExpenseRulesList[index]
          for (let i = 0; i < item.ruleList.length; i++) {
            const ite = item.ruleList[i]
            for (let j = 0; j < ite.ruleDataList.length; j++) {
              const it = ite.ruleDataList[j]
              for (let k = 0; k < it.ruleGroupList.length; k++) {
                const element = it.ruleGroupList[k]

                if (
                  (element.id == id && element.ruleType == 'VALUE') ||
                  (element.id == id && element.ruleType == 'PER_CENT')
                ) {
                  if (element.code != this.sonCode) {
                    element.isAlreadyChange = 1
                  } else {
                    element.isAlreadyChange = 0
                  }

                  throw new Error('EndIterative')
                }
              }
            }
          }
        }
      } catch (e) {}
    },
    focusSonFn(id) {
      try {
        for (let index = 0; index < this.usedExpenseRulesList.length; index++) {
          const a = this.usedExpenseRulesList[index]
          for (let i = 0; i < a.ruleList.length; i++) {
            const b = a.ruleList[i]
            for (let j = 0; j < b.ruleDataList.length; j++) {
              const c = b.ruleDataList[j]
              for (let k = 0; k < c.ruleGroupList.length; k++) {
                const d = c.ruleGroupList[k]
                if (
                  (d.id == id && d.ruleType == 'VALUE') ||
                  (d.id == id && d.ruleType == 'PER_CENT')
                ) {
                  this.sonCode = d.code
                  throw new Error('EndIterative')
                }
              }
            }
          }
        }
      } catch (e) {}
    },
    changeFn() {
      if (this.source == 0) {
        this.$api.performanceRules
          .generalRuleLogList(this.ExpenseRulesModule)
          .then(res => {
            this.changeData = res.data
          })
          .catch(err => {
            console.log(err)
          })
      } else if (this.source == 1) {
        this.$api.project
          .projectRuleSelectRulelogList(this.ExpenseRulesModule)
          .then(res => {
            this.changeData = res.data
          })
          .catch(err => {})
      }
      this.changeDialog = true
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-collapse-item__header {
    font-weight: 900;
    color: #000;
    font-size: 18px;
  }
  /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 5px;
  }
  .expense {
    margin-bottom: 8px;
    // height: 50px;
    width: 100%;
    display: inline-block;
    line-height: 50px;
    .expense_name {
      display: inline-block;
      width: 17%;
      text-align: center;
    }
    .operator {
      margin: 0 8px;
    }
    .rulesInput::after {
      content: '%';
      position: absolute;
      right: 8%;
      bottom: -12%;
    }
    .tips {
      position: relative;
      width: 200px;
      height: 10px;
    }
    .tips::after {
      content: '值不能大于100或小于0';
      position: absolute;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: red;
      font-size: 14px !important;
      left: -21%;
      bottom: -283%;
    }
    .color {
      background-color: #f5e9057d;
    }
    .bd_input {
      border: 1px solid #ccc;
      height: 45px;
      border-radius: 10%;
      text-align: center;
    }
    // 改变input框背景颜色
    /deep/.el-input__inner {
      background-color: transparent !important;
      border: 0 !important;
    }
    /* 利用穿透，设置input边框隐藏 */
    .bd_input >>> .el-input__inner {
      border: 0;
    }
  }
  /deep/.labelClassName {
    width: 20px;
  }
}
</style>
